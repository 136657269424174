import { Controller } from '@hotwired/stimulus';
import "@melloware/coloris/dist/coloris.css";
import Coloris from '@melloware/coloris';

// Connects to data-controller="color-picker"
export default class extends Controller {
  connect() {
    Coloris.init();
    Coloris({
      el: "#guide_theme_color",
      defaultColor: "#000000"
    });
  }
}
